<template>
    <div class="page-container">
        <div class="banner-content">
            <div class="banner">
                <img :src="bannerUrl" alt="">
            </div>
            <div class="page-title-content">
                <div class="page-title">
                    <div class="first-title">采购流程行为准则</div>
                    <div class="two-title">专注PE重包装袋领域</div>
                </div>
            </div>
        </div>
        <div class="page-content-div">
            <div class="page-content">
                <div class="jianjie-content">
                    <div class="text-div">
                        <p class="title-tip">此页采购流程行为准则仅作物前期沟通参考,
                            阅读以下内容提前准备材料后可减少后续沟通时间,
                            需方若存在其他要求或对准则内容有所顾虑，详细协商后可对最终合同进行改变
                        </p>
                        <div class="title">一、产权认证</div>
                        <div class="text">产品外观内容涉及商标等产权关系的，需方应提供相应的商标注册证、营业执照复印件、产品生产委托书，否则供方无法提供生产</div>

                        <div class="title">二、双方敲定成品质量标准</div>
                        <div class="text">质量要求技术标准、供方对质量负责的条件和期限，包装袋质量材质同与需方签订的工艺技术要求</div>

                        <div class="title">三、确认交货地点与方式</div>
                        <div class="text">根据需方要求，指定地点和运输方式</div>

                        <div class="title">四、运输方式及到达站港和费用负担</div>
                        <div class="text">根据双方协商，确定费用负担方</div>

                        <div class="title">五、合理损耗及计算方式</div>
                        <div class="text">由于设备和工艺原因，具体成品数量无法控制，交货尾款将按照合同单价按实际数量结算，下单数量与实际交货将有1~3%的误差，若需方由于订单标准原因（如产品报关等）有明确的具体数量要求，提前协商即可解决</div>

                        <div class="title">六、包装标准、包装物的供应与回收</div>
                        <div class="text">
                            <span class="color-083a69" @click="dialogVisible = true">包装标准详见</span>
                        </div>

                        <el-dialog
                                title="包装标准"
                                :modal="isModal"
                                :close-on-click-modal="true"
                                :visible.sync="dialogVisible"
                                :width="width">
                            <div style="padding: 20px 0">
                                <img style="width: 100%" src="../../assets/dabaofangshi.jpg" alt="">
                            </div>
                            <div style="text-align: right">
                                <el-button size="small" type="primary" @click="dialogVisible = false">确定</el-button>
                            </div>
                        </el-dialog>


                        <div class="title">七、验收标准、方法及提出异议期限</div>
                        <div class="text">按样品标准验收，如有不符应于买方收到货物后7日内书面提出质量异议，如无异议视为质量合格</div>

                        <div class="title">八、随机备品、配件工具数量及供应方式</div>
                        <div class="text">初次使用PE重包装袋产品的客户建议配备专业的封口设备（华联机械集团有限公司），若需方需要有现货，供方可随订单将工厂的库存现货，具体价格参考封口设备市场价格（含税含发票）</div>

                        <div class="title">九、结算方式及期限</div>
                        <div class="text">合同确定后，需方付清货款，供方发货</div>

                        <div class="title">十、如需提供担保，另立合同担保书，作为本合同附件</div>

                        <div class="title">十一、违约责任</div>
                        <div class="text">按实际情况而定；合同确定后，一方无故毁约，需承担另外一方全部损失，并按照每日合同金额3%支付违约金</div>

                        <div class="title">十二、解决合同纠纷的方式</div>
                        <div class="text">双方协商解决，出于供方为需方的风险共担原则，质量及成品工艺技术问题的情况，由供方承担并解决。</div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                width: '600px',
                dialogVisible: false,
                isModal: false,
                bannerUrl: ''
            }
        },
        mounted() {
            const banners = JSON.parse(localStorage.getItem('banners'));
            banners ? this.bannerUrl = banners[6]['imgUrl'] : this.bannerUrl = this.$url.backImg[6];
            this.getWidth();
            let _this = this;
            window.onresize = function() {
                _this.getWidth();
            };
        },
        methods: {
            getWidth() {
                if (document.body.clientWidth > 450) {
                    this.isModal = true;
                    this.width = '600px';
                } else {
                    this.isModal = false;
                    this.width = '80%';

                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .page-content-div{
        display: flex;
        justify-content: center;
        width: 100%;
        .page-content{
            width: 1200px;
        }
    }
    .module-title{
        text-align: center;
        color: rgba(5, 6, 16, 100);
        font-size: 40px;
        margin-bottom: 44px;
        margin-top: 60px;
    }
    .jianjie-content{
        margin-top: 50px;
        .text-div{
            padding: 24px;
            border: 1px solid rgba(233, 233, 238, 1);
            line-height: 36px;
            color: rgba(114, 114, 122, 100);
            font-size: 16px;
            font-family: PingFangSC-Regular;
            .title{
                font-weight: 600;
                color: #000000ad;
            }
            .text{
                padding-left: 30px;
            }
        }
    }
    .color-083a69{
        color: #083a69;
        text-decoration: underline;
        cursor: pointer;
    }
    .title-tip{
        color: #ff8300;
        line-height: 25px;
        font-size: 14px;
    }
    /deep/ .el-dialog__wrapper {
        overflow: inherit;
    }
    /deep/ .el-dialog__body {
        padding: 0 20px 20px;
    }
</style>